import React, { useEffect } from 'react';
import './index.scss'
import { goToAppOrMp } from '../../utils/methods'
import { 
  banner_purchase,
  play_again,
  map_result
} from '../../assets/game'

import { GAService } from '../../services';


const Restart = (props: any) => {
  const Product = { id: 311306, name: 'EF Set', type: 0, desc: '焕颜娇宠套装',  imgPath: banner_purchase }
  useEffect(() => {
    GAService.pageView('mapGame_result')
  })

  function onRestart() {
    if(props.onRestart) {
      props.onRestart()
    }
  }

  function goBuy (id: any) {
    GAService.post('mapGame_result', {
      ec: 'mapGame',
      ea: 'purchaseNow'
    })
    goToAppOrMp(id)
  }

  return (
    <div className="restart">
      <img className='restart__wrapper' src={map_result} alt=''/>
      <div className='restart__block01'>
        <div className='restart__block01__title'>
          重来一次!
        </div>
        <img src={play_again} alt="play-again" onClick={() => onRestart()}/>
      </div>
      <div className='restart__block02'>
        <div 
          className='restart__block02__buy'
          onClick={() => goBuy(Product.id)}
        >
          <img src={Product.imgPath} alt=""/>

        </div>
      </div>
    </div>
  )
}

export default Restart;
