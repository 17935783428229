import React from 'react';
import './index.scss'

const Header = () => {
  return (
    <div className="header">
      <div className="header__title"></div>
    </div>
  )
}

export default Header;
