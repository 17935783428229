import React, { useEffect, useState, useRef } from 'react';
import './index.scss'

import { goToAppOrMp } from '../../utils/methods';

import { GAService } from '../../services';

const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const RuleCard = ({ game, dispatch }: any) => {
  const texts: any = {
    quizz: {
      title: '找寻美丽伙伴',
      desc: '轻轻点击，获取个性化护理建议'
    },    
    map: {
      title: '玩游戏',
      desc: '您知道娇韵诗的产品是由来世界各地的植物制成的吗？现在就和娇韵诗畅游美丽大自然，了解珍稀美妍植物成分。'
    },
    sunrise: {
      title: '即刻探索<br/>日上独家限量套组',
      desc: '6月1日至6月30日可购买'
    }
  }

  const [text, setText] = useState({ title: '', desc: '' })
  const [ruleClass, setRuleClass] = useState('')
  const previousSetup = usePrevious(text)

useEffect(() => {
    const current = JSON.stringify(texts[game])
    const previous = JSON.stringify(previousSetup)
    if (current !== previous) {
      const title: string = texts[game].title
      const desc: string = texts[game].desc
      setText({ title, desc })
      setRuleClass(`rule__container_${game}`)
    }
  }, [game, texts, previousSetup])

  function onStart() {
    let clickType: any = null
    switch (game) {
      case 'map': 
        clickType = 'mapGame'
      break;
      case 'quizz':
        clickType = 'personalizedQuiz'
      break;
      case 'sunrise':
        clickType = 'productShelf'
      break;
      default:
      break;      
    }
    GAService.post('homePage', {
      ec: 'home',
      ea: 'clickToView',
      el: clickType
    })

    if(game === 'sunrise') {
      goToAppOrMp("")
    } else {
      dispatch('LAUNCH_GAME')
    }
  }
  return (
    <div className="rule">
      <div className={ruleClass}>
        <div className="rule__text">
          <div className="rule__text__title" dangerouslySetInnerHTML={{__html: text.title}}></div>
          <div className="rule__text__desc">{ text.desc }</div>
        </div>
        <div className={`${ruleClass}__cta`} onClick={() => onStart()}></div>
      </div>
    </div>
  )
}

export default RuleCard;
