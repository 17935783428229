

export function goToAppOrMp(id: any) {
    let source = GetQueryString('source')
    if (source != null) {
      let  _source = decodeURIComponent(source)
      if (_source === 'mp') {
        // @ts-ignore
          wx.miniProgram.navigateTo(
            { url:`/packages/clarins/index?id=${id}` }
          )
      } else {
        console.log(window)
        let params = {
          id: (id && id.toString()) || ""
        }
        // @ts-ignore
        window && window.H5_31ten && window.H5_31ten.postMessage(JSON.stringify(params))
      }
    }
}

function GetQueryString(name: any) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = decodeURI(window.location.search.substr(1)).match(reg);
  if (r !== null) return unescape(r[2])
  return null;
}

export function isAppOrMp() {
  let source: any = GetQueryString('source')
  let _source = decodeURIComponent(source)
  if (_source === 'mp') {
    return 'mp'
  } else {
    return 'app'
  }
}


export default {
  goToAppOrMp,
  isAppOrMp
};
