import React, { useEffect } from 'react';
import './index.scss'
import { 
  stamp_winner,
  wave,
  banner_purchase,
  map_result,
  product_australia, 
  product_france,
  product_india1, 
  product_india2
} from '../../assets/game'

import { goToAppOrMp } from '../../utils/methods'

import { GAService } from '../../services';

const PurchaseWinner = (props: any) => {
  const products = [
    product_france, 
    product_australia, 
    product_india2,
    product_india1
  ]

  const Product = { id: 311306, name: 'EF Set', type: 0, desc: '焕颜娇宠套装',  imgPath: banner_purchase }

  useEffect(() => {
    GAService.pageView('mapGame_result')
  }, [])


  function goBuy (id: any) {
    GAService.post('mapGame_result', {
      ec: 'mapGame',
      ea: 'purchaseNow'
    })
    goToAppOrMp(id)
  }

  return (
    <div className="winner">
      <img className='winner__wrapper' src={map_result} alt=''/>
      <div className='winner__block01'>
        <div className='winner__block01__inner'>
          <div className='winner__block01__text'>恭喜过关!</div>
          <img className='winner__block01__banner' src={stamp_winner} alt=""/>
        </div>
      </div>
      <div className='winner__block02'>
        <div className='winner__block02__product product01'>
          <img src={products[0]} alt=""/>
        </div>
        <div className='winner__block02__product product02'>
          <img src={products[1]} alt=""/>
        </div>
        <div className='winner__block02__product'>
          <img src={products[2]} alt=""/>
        </div>
        <div className='winner__block02__product product04'>
          <img src={products[3]} alt=""/>
        </div>
      </div>
      <div className='winner__block03'>
        <img className='winner__block03__wave'src={wave} alt=""/>
        <div 
          className='winner__block03__buy'
          onClick={() => goBuy(Product.id)}
        >
          <img src={Product.imgPath} alt=""/>
        </div>
        
      </div>
    </div>
  )
}

export default PurchaseWinner;
