import React, { useEffect } from 'react'
import './index.scss'
import { 
  product0,
  product1,
  product2,
  product3
} from '../../assets/quizz'

import { goToAppOrMp } from '../../utils/methods';
import { GAService } from '../../services';

const Result = (props: any) => {
  const products: any = [
    { id:  311307, name: 'MA Set', type: 0, desc: '赋活娇宠套装', imgPath: product0},
    { id: 311306, name: 'EF Set', type: 0, desc: '焕颜娇宠套装',  imgPath: product1 },
    { id: 311305, name: 'Mascara Set', type: 1, desc: '大放睛采套装',  imgPath: product2},
    { id: 311304, name: 'Lipstick Set', type: 1, desc: '水润丰盈唇膏套装', imgPath: product3 }
  ]


  let result: any = null

  const { formData } = props


  if(formData.q2 === 0) {
    if(formData.q3 === 0) {
      result = products[0]
    } else {
      result = products[1]
    }
  } else {
    if(formData.q4 === 0) {
      result = products[2]
    } else {
      result = products[3]
    }
  }

  useEffect(() => {
    GAService.pageView(`quizResult_${result.name}`)
  }, [result])


  function goBuy (result: any) {
    GAService.post('quizResult', {
      ec: 'quiz_result',
      ea: 'purchaseNow',
      el: result.desc,
    })
    goToAppOrMp(result.id)
  }

  return (
    <div className='result'>
      <img className='result__bgc' src={`${process.env.REACT_APP_OSS}background.jpg`} alt=""/>
      <div className='result__body'>
        <img src={result.imgPath} alt="img"/>
      </div>
      <div className='result__btn' onClick={() => goBuy(result)}>
        立即购买
      </div>
    </div>
  )
}

export default Result;
