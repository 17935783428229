export const australia_map = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/australia.svg'
export const banner_purchase = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/banner.png'

export const france_map = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/france.svg'
export const india_map = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/india.svg'
export const map_result = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/map-result.jpg'
export const pin_australia = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/pin-australia.png'
export const pin_france = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/pin-france.png'
export const pin_india1 = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/pin-india1.png'
export const pin_india2 = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/pin-india2.png'
export const play_again = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/play-again.png'
export const popup_map = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/popup.gif'
export const product_australia = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/product-australia.png'
export const product_france = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/product-france.png'
export const product_india1 = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/product-india1.png'
export const product_india2 = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/product-india2.png'
export const stamp_title = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/stamp-title.png'
export const stamp_winner = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/stamp-winner.png'
export const wave = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/wave.png'
export const cta = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/cta.svg'
export const airplane = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/airplane.svg'






