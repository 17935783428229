import React, { useEffect, useState, useRef } from 'react';
import './index.scss';

import { GAService } from '../../services';


const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  });
  return ref.current
}

const Tabbar = ({ game, dispatch, clickTab }: any) => {

  // MAP
  // SUNRISE
  // QUIZZ
  const map: any = { text: '玩游戏', action: 'MOVE_TO_MAP' }
  const quizz: any = { text: '找寻美丽伙伴', action: 'MOVE_TO_QUIZZ' }
  const sunrise: any = { text: '即刻探索日上独家限量套组', action: 'MOVE_TO_SUNRISE' }

  const games: any = {
    map: {
      left: quizz,
      right: sunrise
    },
    sunrise: {
      left: map,
      right: quizz
    },
    quizz: {
      left: sunrise,
      right: map
    }
  }

  const [setup, setSetup] = useState({ left: { text: '', action: '' }, right: { text: '', action: '' } })
  const previousSetup = usePrevious(setup)

  useEffect(() => {
    const current = JSON.stringify(games[game])
    const previous = JSON.stringify(previousSetup)
    if (current !== previous) {
      setSetup(games[game])
    }
  }, [game, games, previousSetup])

  function goBack() {
    if(clickTab === 'quizz') {
      if (game === 'quizz') {
        GAService.post('quizResult', {
          ec: 'quiz_result',
          ea: 'otherOptions',
          el: 'productShelf'
        })
      }
    }
    if(clickTab === 'map') {
      if (game === 'map') {
        GAService.post('mapGame_result', {
          ec: 'mapGame',
          ea: 'otherOptions',
          el: 'personalizedQuiz'
        })
      }
    }
    dispatch('BACK_HOME'); 
    dispatch('DEFAULT_RESET_SCENE'); 
    dispatch(setup.left.action) 

  }

  function goHome() {
    if (clickTab === 'quizz' && game === 'quizz') {
        GAService.post('quizResult', {
          ec: 'quiz_result',
          ea: 'otherOptions',
          el: 'home'
        })
    }
    if (clickTab === 'map' && game === 'map') {
        GAService.post('mapGame_result', {
          ec: 'mapGame',
          ea: 'otherOptions',
          el: 'home'
        })
    }
    dispatch('BACK_HOME')
  }

  function goNext() {
    if(clickTab === 'quizz') {
      if (game === 'quizz') {
        GAService.post('quizResult', {
          ec: 'quiz_result',
          ea: 'otherOptions',
          el: 'mapGame'
        })
      }
    }
    if(clickTab === 'map') {
      if (game === 'map') {
        GAService.post('mapGame_result', {
          ec: 'mapGame',
          ea: 'otherOptions',
          el: 'productShelf'
        })
      }
    }

    dispatch('BACK_HOME'); 
    dispatch('DEFAULT_RESET_SCENE'); 
    dispatch(setup.right.action)

  }

  return (
    <div className="tabbar">
      <div className="tabbar__container">
        <div className="tabbar__container__icon tabbar__container__back" onClick={() =>goBack() } dangerouslySetInnerHTML={{ __html : setup.left.text || '' }}></div>
        <div className="tabbar__container__icon tabbar__container__home" onClick={ () => goHome()}></div>
        <div className="tabbar__container__icon tabbar__container__next" onClick={ () => goNext()} dangerouslySetInnerHTML={{ __html : setup.right.text || '' }}></div>
      </div>
    </div>
  )
}

export default Tabbar;
