import { isAppOrMp } from '../utils/methods';

const USER_AGENT = navigator.userAgent;

let parser = require('ua-parser-js')
let SYS = parser(USER_AGENT)

let PAGE_NAME: any = null

  // @ts-ignore
  gtag('set', {
		dimension1: USER_AGENT,
		dimension2: '',
		dimension3: '',
		dimension4: '',
		dimension5: 'Clarins_H5',
		dimension6: SYS.os.name + SYS.os.version || '',
		dimension7: SYS.os.version || '',
		dimension8: navigator.appName || '',
		dimension9: SYS.device.model || '',
		dimension10: navigator.language,
		dimension11: isAppOrMp() && isAppOrMp()
	});


class GAService {
	public static pageView(
		pageName: string = ''
	) {
		console.log(pageName)

    // @ts-ignore
    gtag('event', 'page_view', {
      page_title: pageName,
    });
		PAGE_NAME = pageName
	}

	public static async post(pageName: any, payload: any): Promise<any> {
		console.log(payload)
    // @ts-ignore
    gtag('event', payload.ea || '', {
      event_category: payload.ec || '',
      event_label: payload.el || '',
      page_title: PAGE_NAME || ''
    });
	}
}

export default  GAService;



