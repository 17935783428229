import React, { useState } from 'react';
import './index.scss'
import { australia_map, france_map, india_map, product_australia, product_france, product_india1, product_india2, stamp_title, cta, airplane  } from '../../assets/game';
import MapGame from '../map-game/MapGame'


const Launch = (props: any) => {
  const [showMapGame, setShowMapGame] = useState<any>(false)
  const firstMap = [{
      id: 1,
      name: '有机起绒草',
      city: '法国',
      imageMap: france_map,
      imageProduct: product_france
    },
    {
      id: 2,
      name: '袋鼠花',
      city: '澳大利亚',
      imageMap: australia_map,
      imageProduct: product_australia
    }
  ]

  const secondMap = [{
      id: 3,
      name: '芒果',
      city: '印度',
      imageMap: india_map,
      imageProduct: product_india1
    },
    {
      id: 4,
      name: '姜黄',
      city: '印度',
      imageMap: india_map,
      imageProduct: product_india2
    }
  ]
  
  let [count, setCount] = useState(15)
  let timer:any = null

  let [showCountDown, setShowCountDown] = useState(false)


  function countdown() {
    timer = setInterval(() => {
      if(count <= 0) {
        setShowMapGame(true)
        clearInterval(timer)
        return
      }
      count = count - 1
      setCount(count)
    }, 1000);
  }

  function onStartGame() {
    setShowCountDown(true)
    countdown()
  }

  return (
    <div>
      {!showMapGame && <div className="launch">
        <div className='launch__image'>
          <img src={stamp_title} alt=''></img>
          <div className='launch__text'>测一测您的记忆力</div>
          <div>在15秒内，完美记住4种自然植萃的来源地</div>
        </div>
        <div className='launch__content'>
          <div className='launch__map'>
            {
              firstMap.map((map, index) => {
                return <div className='launch__item' key={ index }>
                  <div className={`launch__item__wrapper ${showCountDown ? 'name': ''}`}>
                    <img className='launch__item__map' src={map.imageMap} alt=''/>
                    {showCountDown && <img className='launch__item__image' src={map.imageProduct} alt=''/>}
                  </div>
                  {showCountDown && <div className='launch__item__label'>{map.name}</div>}
                  <div className='launch__item__text'>{map.city}</div>
                </div>
              })
            }
          </div>
          <div className='launch__count'>
    
            { showCountDown ? <div className='launch__count__circle'>
              <div className='c-cricle'>
                <img  className='plane' src={airplane} alt="airplane"/>
              </div>
              <div className="box">
                <div className="left_box">
                  <div className="left_item"></div>
                </div>
                <div className="right_box">
                  <div className="right_item"></div>
                </div>
                <div className="mask"></div>
              </div>
              <div className='launch__count__circle__countdown'>
                <div className='launch__count__number'>{count}</div>
                <div>秒</div>
              </div>
              
            </div> : 
            <img 
              src={cta} 
              alt='cta'
              onClick={() => onStartGame()}
            />
            }
            <div>{showCountDown ? '游戏即将开始' : '点击开始计时'}</div>
          </div>
          <div className='launch__map map2'>
            {
              secondMap.map((map, index) => {
                return <div className='launch__item' key={ index }>
                  <div className='launch__item__wrapper'>
                    <img className='launch__item__map' src={map.imageMap} alt='' />
                  {showCountDown && <img className='launch__item__image' src={map.imageProduct} alt='' />}
                  </div>
                  {showCountDown && <div className='launch__item__label'>{map.name}</div>}
                  <div className='launch__item__text'>{map.city}</div>
                </div>
              })
            }
          </div>
        </div>
      </div>}
      {!!showMapGame && <MapGame dispatch={props.dispatch}/>}
    </div>
  )
}

export default Launch;
