import React, { useState, useEffect } from 'react'
import './index.scss'
import { 
  icon_angry, 
  icon_gloomy, 
  icon_happy, 
  icon_sade, 
  icon_veryHappy,
  icon_angry_active, 
  icon_gloomy_active, 
  icon_happy_active,  
  icon_sade_active, 
  icon_veryHappy_active
} from '../../assets/quizz'
import { stamp_title } from '../../assets/game';
import Result from '../result/Result';
import { GAService } from '../../services';

const Quiz = (props: any) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isShowResult, setShowResult] = useState(false)
  const questions = [
    '您今天心情如何？',
    "今天想入手的是...",
    "您最想达到的护肤效果是...",
    "您平常偏爱的彩妆风格..."
  ]
  const options1: any = [
    { id: 0, value: 'Angry', imgPath: icon_angry, imgActive: icon_angry_active },
    { id: 1, value: 'Sade', imgPath: icon_sade, imgActive: icon_sade_active },
    { id: 2, value: 'Gloomy', imgPath: icon_gloomy, imgActive: icon_gloomy_active},
    { id: 3, value: 'Happy', imgPath: icon_happy, imgActive: icon_happy_active},
    { id: 4, value: 'Very happy', imgPath: icon_veryHappy, imgActive: icon_veryHappy_active }
  ]

  const options2: any = [
    { id: 0, label: 'A', value: '护肤' },
    { id: 1, label: 'B', value: '彩妆' }
  ]
  

  const options3: any = [
    { id: 0, label: 'A', value: '提亮肤色，抵御细纹' },
    { id: 1, label: 'B', value: '紧致，提拉，淡纹' }
  ]

  const options4: any = [
      { id: 0, label: 'A', value: '深邃迷人的眼妆'},
      { id: 1, label: 'B', value: '妆点愉悦心情，拥有全天好气色'}
  ]

  const [step, setStep] = useState([1])
  const [currentOptions, setCurrentOptions] = useState(options1)
  const [formData, setFormData] = useState({
      q1: -1, q2: -1, q3: -1, q4: -1
  })
  const [isDisabled, setDisabled] = useState(true)
  
  useEffect(() => {
    GAService.pageView('personalized_step1')
  }, [])


  function onNext(currentIndex: any) {

    if(isDisabled) { return }
    const { dispatch } = props
    if(currentIndex === 4) {
      if(formData.q2 === 0) {
        GAService.post('quiz_skinCare', {
          ec: 'quiz_skinCare',
          ea: formData.q3 === 0 ? 'combatAgeing' : 'firming&Lifting'
        })
      } else {
        GAService.post('quiz_makeUp', {
          ec: 'quiz_makeUp',
          ea: formData.q4 === 0 ? 'expressiveEyes' : 'popOfColour'
        })
      }
    }
    if(currentIndex === 3 && formData.q2 === 0) {
        GAService.post('quiz_skinCare', {
          ec: 'quiz_skinCare',
          ea: formData.q3 === 0 ? 'combatAgeing' : 'firming&Lifting'
        })
      dispatch('QUIZZ_RESULT')
      setShowResult(true)
      return
    } else {
      if (currentIndex >= questions.length || isDisabled) {
        dispatch('QUIZZ_RESULT')
        setShowResult(true)
        return
      }
    }

    if(formData.q2 === 1) {
      setCurrentQuestion(currentIndex + 1)
      getStep(currentIndex + 1)
    } else {
      setCurrentQuestion(currentIndex)
      getStep(currentIndex)
    }
    switch(currentIndex + 1) {
      case 2:
        let mood: any = ''
        switch(formData.q1) {
          case 0: 
            mood = 'angry'
            break;
          case 1: 
            mood = 'sad'
            break;
          case 2:
            mood = 'gloomy'
            break;
          case 3:
            mood = 'happy'
            break;
          case 4:
            mood = 'veryHappy'
            break;
          default:
        }
        GAService.post('personalized_step1', {
          ec: 'quiz_step1',
          ea: mood
        })
        GAService.pageView('personalized_step2')
        setCurrentOptions(options2)
      break;
      case 3:
        GAService.post('personalized_step2', {
          ec: 'quiz_step2',
          ea: formData.q2 === 0 ? 'skinCare' : 'makeUp'
        })

        if (formData.q2 === 1) {
          setCurrentOptions(options4)
          GAService.pageView('quiz_makeUp')

        } else {
          GAService.pageView('quiz_skinCare')
          setCurrentOptions(options3)
        }
      break;
      case 4:
        setCurrentOptions(options4)
      break;
      default: 
        break;
    }
    if(!isDisabled) {
      setDisabled(true)
    }
  }

  function getStep(currentQuestion: any) {
    let tempArray: any = []
    for(let i = 0; i < currentQuestion + 1; i++) {
      tempArray.push(i)
    }
    setStep(tempArray)
  }
  function q1Handle(number: any) {

    setFormData((prev) => {
      prev.q1 = number;
      return {
        ...prev,
      };
    });
    setDisabled(false)
  }

  function q2Handle(number: any) {
    setFormData((prev) => {
      prev.q2 = number;
      return {
        ...prev,
      };
    });
    setDisabled(false)
  }
  function q3Handle(number: any) {
    setFormData((prev) => {
      prev.q3 = number;
      return {
        ...prev,
      };
    });
    setDisabled(false)
  }
  function q4Handle(number: any) {
    setFormData((prev) => {
      prev.q4 = number;
      return {
        ...prev,
      };
    });
    setDisabled(false)
  }

  return (
    <div>

      {!isShowResult ?  <div className="quiz">
      <img  className='bgc' src={`${process.env.REACT_APP_OSS}background.jpg`} alt=""/>
      <div className='quiz__title'>
        <img src={stamp_title} alt="stamp_title"/>
        <div className='quiz__text'>
          轻轻点击
        </div>
        <div className='quiz__text'>
          获取个性化护理建议
        </div>
      </div>
      <div className='quiz__step'>
        <div className='quiz__step__number'>
          <span>{ `问题 ${currentQuestion === 3 ? 3 : currentQuestion + 1}` }</span>
        </div>
        <div className='quiz__step__progress'>
          {
            step.map((item: any, index: any) => {
              return <div 
                className='quiz__step__progress__item'
                key={index}
              ></div>
            })
          }
        </div>
      </div>
      <div className='quiz__choose'>
        <div className='quiz__choose__image01'></div>
        <div className='quiz__choose__image02'></div>
        <div className='quiz__choose__image03'></div>

        {
          ['Q1', 'Q2', 'Q3', 'Q4'].map((item, questionIndex) => {
            return (currentQuestion === questionIndex && 
              <div 
                className='questions'
                key={questionIndex}
              >
                <div className='questions__title'>
                  {questions[questionIndex]}
                </div>
                {currentQuestion === 0 && <div className='questions__form form1'>
                  {options1.map((item: any, index: any) => {
                    const isActive = formData.q1 === index
                    return <div 
                              className='q1'
                              key={index}
                              onClick={() =>q1Handle(index)}
                            >
                      <img className='icon' src={isActive ? item.imgActive : item.imgPath} alt={isActive ? item.imgActive : item.imgPath} />
                    </div>
                  })}
                </div>}
                {currentQuestion === 1 && <div className='questions__form'>
                  {currentOptions.map((item: any, index: any) => {
                    return <div 
                            className={`option q2 ${formData.q2 === index ? 'active' : ''}`} 
                            key={index}
                            onClick={() => q2Handle(index)}
                          >
                      <span className='option__text'>{item.value}</span>
                    </div>
                  })}
                </div>}
                {currentQuestion === 2 && <div className='questions__form'>
                  {currentOptions.map((item: any, index: any) => {
                    return <div 
                            className={`option q2 ${formData.q3 === index ?'active' : ''}`} 
                            key={index}
                            onClick={() => q3Handle(index)}
                          >
                      <span className='option__text'>{item.value}</span>
                    </div>
                  })}
                </div>}
                {currentQuestion === 3 && <div className='questions__form'>
                  {currentOptions.map((item: any, index: any) => {
                    return <div 
                            className={`option q2 ${formData.q4 === index ?'active' : ''}`} 
                            key={index}
                            onClick={() => q4Handle(index)}
                          >
                      <span className='option__text'>{item.value}</span>
                    </div>
                  })}
                </div>}
              </div>
            )
          })
        }
        <div
          className={`quiz__next ${isDisabled ? '' : 'active'}`}
          onClick={() => onNext(currentQuestion + 1)}
        >
          下一题
        </div>
      </div>
    </div> :  <Result formData={formData}/>}
    </div>
  )
}

export default Quiz;
