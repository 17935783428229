import React, { useEffect } from 'react';
import './index.scss';
import { popup_map  } from '../../assets/game'
const Popup = (props: any) => {
    useEffect(() => {
    }, [props])

    function onStart() {
        if(props.onStart) {
            props.onStart()
        }
    }
    return (
        <div className='popup'>
            <div className='popup__inner'>
                <img src={popup_map} alt=""/>
                <div className='popup__inner__body'>
                    <div className='popup__inner__title'>
                        把每种植萃拉到正确的来源地
                    </div>
                    <div 
                        className='popup__inner__btn'
                        onClick={() => onStart()}
                    >
                        开始挑战
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Popup
