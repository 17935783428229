import React, { useState } from 'react';
import Header from './components/header/Header'
import Splash from './modules/splash/Splash'
import Rendering from './modules/rendering/Rendering'
import './App.scss';

const App = () => {
  const [introLoaded, setIntroLoaded] = useState(true)
  const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <div className="App">
      <Header />
      {/* {!introLoaded && <Splash setIntroLoaded={setIntroLoaded} />} */}
      {(introLoaded || isIos) && <Rendering/>}
    </div>
  )
}

export default App;
