import React, { useState, useEffect, useRef } from 'react';
import Popup from '../../components/popup/Popup'
import { australia_map, france_map, india_map, pin_australia, pin_france, pin_india1, pin_india2, stamp_title  } from '../../assets/game'
import './index.scss'

import PurchaseWinner from '../purchase-winner/winner'
import Restart from '../purchase-restart/Restart';
import { GAService } from '../../services';


const MapGame = (props: any) => {
  const gameMap: any = [
    {
      id: 1,
      name: '袋鼠花',
      city: '澳大利亚',
      imageMap: australia_map
    },
    {
      id: 2,
      name: '姜黄',
      city: '印度',
      imageMap: india_map,
    },
    {
      id: 3,
      name: '芒果',
      city: '印度',
      imageMap: india_map
    },
    {
      id: 4,
      name: '有机起绒草',
      city: '法国',
      imageMap: france_map
    }
  ]

  const productRef: any = useRef()
  const mapRef: any = useRef()
  const titleRef: any = useRef()
  const mapItemRef: any = useRef()
  const products = [pin_france, pin_australia, pin_india1, pin_india2]
  const [isShow, setShow] = useState(true)
  const [isShowBtn, setShowBtn] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [showRestart, setShowRestart] = useState(false)
  const [eleHeight, setEleHeight] = useState(0)
  const [titleHeight, setTitleHeight] = useState(0)

  const [mapItemHeight, setMapHeight] = useState(0)
  const [mapItemWidth, setMapWidth] = useState(0)


  let startX: number = 0
  let startY: number = 0
  let disX: number = 0
  let disY: number = 0
  let clientX: number = 0
  let clientY: number = 0
  let mapInfo: any = [
    { id: 0, top: 0, left: 0, right: 0, bottom: 0, isSected: false },
    { id: 1, top: 0, left: 0, right: 0, bottom: 0, isSected: false },
    { id: 2, top: 0, left: 0, right: 0, bottom: 0, isSected: false },
    { id: 3, top: 0, left: 0, right: 0, bottom: 0, isSected: false }
  ]
  let current: any = [
    { id: 0, currentX: 0, currentY: 0 },
    { id: 1, currentX: 0, currentY: 0 },
    { id: 2, currentX: 0, currentY: 0 },
    { id: 3, currentX: 0, currentY: 0 }
  ]
  let targetInfo: any = [
    {
      id: 0,
      top: 0,
      left: 0,
      status: {
        map0: false,
        map1: false,
        map2: false,
        map3: false
      }
    },
    {
      id: 1,
      top: 0,
      left: 0,
      status: {
        map0: false,
        map1: false,
        map2: false,
        map3: false
      }
    },
    {
      id: 2,
      top: 0,
      left: 0,
      status: {
        map0: false,
        map1: false,
        map2: false,
        map3: false
      }
    },
    {
      id: 3,
      top: 0,
      left: 0,
      status: {
        map0: false,
        map1: false,
        map2: false,
        map3: false
      }
    }
  ]

  useEffect(() => {
    GAService.pageView('mapGame')
  }, [])


  useEffect(() => {

    setMapWidth(mapItemRef.current.clientWidth)
    setMapHeight(mapItemRef.current.clientHeight)
    mapRef && setEleHeight(mapRef.current.childNodes[0].clientHeight)
    titleRef && setTitleHeight(mapRef.current.childNodes[0].clientHeight)
    mapRef && mapRef.current.childNodes.forEach((ele: any, index: any) => {
      mapInfo[index].top = ele.offsetTop
      mapInfo[index].left = ele.offsetLeft
      mapInfo[index].right = ele.clientWidth + ele.offsetLeft
      mapInfo[index].bottom = ele.clientHeight + ele.offsetTop
    })

    productRef && productRef.current.childNodes.forEach((ele: any, index: any) => {
      targetInfo[index].top = ele.offsetTop
      targetInfo[index].left = ele.offsetLeft
    })
  // eslint-disable-next-line
  }, [isShow, productRef, mapRef, isShowBtn])

  function onDragStart(e: any, index: number) {
    if(isShowBtn) { return }
    let target = e.target
    if(e.changedTouches) {
      startX = e.changedTouches[0].pageX
      startY = e.changedTouches[0].pageY
    }
    disX = startX - target.offsetLeft
    disY = startY - target.offsetTop
  }
  function onDragMove(e: any, index: number) {
    if(e.type !== 'touchmove') {
      e.preventDefault()
    }
    if(isShowBtn) { return }
    if(e.changedTouches) {
      clientX = e.changedTouches[0].pageX
      clientY = e.changedTouches[0].pageY
    }
    let left = clientX - disX
    let top = clientY - disY
    productRef.current.childNodes[index].style.top = `${top + current[index].currentY}px`
    productRef.current.childNodes[index].style.left = `${left + current[index].currentX}px`
  }
  function onDragEnd(e: any, index: number) {
    console.log(mapItemWidth, mapItemHeight, 'mapItemWidth')
    if(isShowBtn) { return }
    initStatus(index)
    if (clientX < mapInfo[0].right && clientX > mapInfo[0].left && clientY >    mapInfo[0].top && clientY < mapInfo[0].bottom) {
      if(judgeIsSelected(0)) {
        resetPos(index)
        return
      }
      productRef.current.childNodes[index].style.top = `-${(targetInfo[index].top - titleHeight + 0.2 * mapItemHeight)}px`

      if (index === 0) {
        productRef.current.childNodes[index].style.left = `${mapItemWidth / 2}px`
      } else {
        productRef.current.childNodes[index].style.left = `-${targetInfo[index].left - mapItemWidth / 2}px`
      }
      targetInfo[index].status.map0 = true

    } else if (clientX < mapInfo[1].right && clientX > mapInfo[1].left && clientY > mapInfo[1].top && clientY < mapInfo[1].bottom) {
      if(judgeIsSelected(1)) {
        resetPos(index)
        return
      }
        productRef.current.childNodes[index].style.top = `-${(targetInfo[index].top - titleHeight + 0.2 * mapItemHeight)}px`
        productRef.current.childNodes[index].style.left = `${targetInfo[3-index].left - 0.3 * mapItemWidth}px`
      targetInfo[index].status.map1 = true

    } else if (clientX < mapInfo[2].right && clientX > mapInfo[2].left && clientY > mapInfo[2].top && clientY < mapInfo[2].bottom) {
      if(judgeIsSelected(2)) {
        resetPos(index)
        return
      }
        productRef.current.childNodes[index].style.top = `-${(targetInfo[index].top - titleHeight  - eleHeight - 20)}px`
        if (index === 0) {
          productRef.current.childNodes[index].style.left = `${mapItemWidth / 2}px`
        } else {
          productRef.current.childNodes[index].style.left = `-${targetInfo[index].left - mapItemWidth / 2}px`
        }
      targetInfo[index].status.map2 = true

    } else if (clientX < mapInfo[3].right && clientX > mapInfo[3].left && clientY > mapInfo[3].top && clientY < mapInfo[3].bottom) {
      if(judgeIsSelected(3)) {
        resetPos(index)
        return
      }
        productRef.current.childNodes[index].style.top = `-${(targetInfo[index].top - titleHeight  - eleHeight - 20)}px`
        productRef.current.childNodes[index].style.left = `${targetInfo[3-index].left - 0.3 * mapItemWidth}px`
      targetInfo[index].status.map3 = true

    } else {
      productRef.current.childNodes[index].style.top = 0
      productRef.current.childNodes[index].style.left = 0
    }
    calcPos(index)
    checkGameIsFinished()
  }

  function resetPos(index: any) {
    productRef.current.childNodes[index].style.top = 0
    productRef.current.childNodes[index].style.left = 0
    current[index].currentY = 0
    current[index].currentX = 0
  }

  function calcPos(index: any) {
    current[index].currentY = Number(productRef.current.childNodes[index].style.top.replace('px', ''))
    current[index].currentX = Number(productRef.current.childNodes[index].style.left.replace('px', ''))
  }

  function initStatus (index: any) {
    targetInfo[index].status.map0 = false
    targetInfo[index].status.map1 = false
    targetInfo[index].status.map2 = false
    targetInfo[index].status.map3 = false
  }

  function judgeIsSelected(num: number) {
    let isSelected = targetInfo.some((ele: any)=> {
      return ele.status[`map${num}`] === true
    })
    return isSelected
  }

  function onStartGame() {
    setShow(false)
  }

  function checkGameIsFinished() {
    let map0Checked = false
    let map1Checked = false
    let map2Checked = false
    let map3Checked = false
    map0Checked = targetInfo.some((item: any) => {
      return item.status.map0 === true
    })
    map1Checked = targetInfo.some((item: any) => {
      return item.status.map1 === true
    })
    map2Checked = targetInfo.some((item: any) => {
      return item.status.map2 === true
    })
    map3Checked = targetInfo.some((item: any) => {
      return item.status.map3 === true
    })
    if (map0Checked && map1Checked && map2Checked && map3Checked) {
      setShowBtn(true)
      if(!(!!targetInfo[0].status.map3 && !!targetInfo[1].status.map0)) {
        setShowRestart(true)
      }
    }   
  }

  function onConfirm() {
    const { dispatch } = props
    dispatch('LOAD_GAME_MAP')
    setShowResult(true)
  }
  function onRestart() {
    const { dispatch } = props
    setShowResult(false)
    setShowBtn(false)
    setShowRestart(false)
    dispatch('LAUNCH_GAME')

  }
  return (
    <div>
      {!showResult && <div className="game">
        <div className='game__wrapper' ref={titleRef}>
          <img className='game__wrapper__image' src={stamp_title} alt="title"></img>
          <div className='game__wrapper__text'>把每种植萃拉到</div>
          <div className='game__wrapper__text'>正确的来源地</div>
        </div>
        <div className='game__map' ref={mapRef}>
          {
            gameMap.map((map: any, index: number) => {
              return <div className='game__item' key={ index } ref={mapItemRef}>
                <div className='game__item__wrapper'>
                  <img className='game__item__map' src={map.imageMap} alt="map" />
                </div>
                <div className='game__item__text'>{map.city}</div>
              </div>
            })
          }
        </div>
        {isShowBtn && <div 
          className='game__confirm'
          onClick={() => onConfirm()}
        >
          确认
        </div>}
        <div className='game__bottom' ref={productRef}>
          {products.map((product, index) => {
            return <div 
              className='game__product' 
              key={index}
              onTouchStart={(e) => onDragStart(e, index)}
              onTouchMove={(e) => onDragMove(e, index)}
              onTouchEnd={(e) => onDragEnd(e, index)}
              >

                <img className='game__product__image' src={product} alt="product" />
            
            </div>
          })}
        </div>
        {isShow && <Popup
          onStart={() =>onStartGame()}
        />}
      </div>}
      {showResult && <div>
        {!showRestart && <PurchaseWinner />}
        {showRestart && <Restart onRestart={() => onRestart()}/> }
      </div>}
      
    </div>
  )
  
}

export default MapGame;
    