import React, { FC, Suspense, useEffect, useRef, useReducer, useState } from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DoubleSide, Color } from 'three'
import { Canvas, useLoader, useThree, useFrame } from 'react-three-fiber'
import { Html, OrbitControls, Stats } from 'drei'
import Tabbar from '../../components/tabbar/Tabbar'
import RuleCard from '../../components/ruleCard/RuleCard'
import LaunchGame from '../launch-game/LaunchGame'
import Quiz from '../quiz/Quiz'
import './index.scss';
import { GAService } from '../../services';
const TWEEN = require('es6-tween')

/*
** Store
*/
const initialState = {
  isLoaded: false,
  isShowroom: true,
  enableRotate: true,
  enableZoom: true,
  resetScene: false,
  showTabbar: false,
  game: '',
  launch: false,
  move: false,
  isZooming: false
};

const stateReducer = (state: any, action: any) => {
  switch (action) {
    case 'FIRST_INIT':
      return { ...state, isLoaded: true }
    case 'ZOOM_TO':
      return { ...state, isShowroom: false, enableRotate: false, enableZoom: false, resetScene: false, move: true }
    case 'BACK_HOME':
      return { ...state, resetScene: true, showTabbar: false, launch: false, enableRotate: false , enableZoom: false, clickTab: '' }
    case 'LOAD_GAME_QUIZZ':
      return { ...state, game: 'quizz', showTabbar: true, move: false }
    case 'LOAD_GAME_MAP':
      return { ...state, game: 'map', showTabbar: true, move: false }
    case 'LOAD_SUNRISE':
      return { ...state, game: 'sunrise', showTabbar: true, move: false }
    case 'MOVE_TO_MAP':
      return { ...state, game: 'map', move: false, clickTab: '' }
    case 'MOVE_TO_QUIZZ':
      return { ...state, game: 'quizz', move: false, clickTab: '' }
    case 'MOVE_TO_SUNRISE':
      return { ...state, game: 'sunrise', move: false, clickTab: '' }
    case 'LAUNCH_GAME':
      return { ...state, launch: true, showTabbar: false, clickTab: 'map' }
    case 'QUIZZ_RESULT':
      return { ...state, launch: true, showTabbar: true, clickTab: 'quizz' }
    case 'DEFAULT_RESET_SCENE':
      return { ...state, resetScene: false, game: '', isShowroom: true, enableRotate: true, enableZoom: true,  move: false }
    case 'USER_ZOOM_IN':
      return { ...state, isZooming: true }
    case 'USER_ZOOM_OUT':
      return { ...state, isZooming: false }
    default:
      return state;
  }
};
// END STORE

// TRIGGER Video
// eslint-disable-next-line
function onVideoClick(camera: any, mesh: any, dispatch: any) {
  new TWEEN.Tween(camera.position)
    .to({
      x: 249.93,
      y: 1.53,
      z: 5.49
    }, 2500)
    .interpolation(TWEEN.Interpolation.CatmullRom)
    .easing(TWEEN.Easing.Quintic.InOut)
    .start()
    .on('complete', () => {
      new TWEEN.Tween(mesh.position)
        .to({
          x: 80,
          y: 25,
          z: 28
        }, 1500)
        .interpolation(TWEEN.Interpolation.CatmullRom)
        .easing(TWEEN.Easing.Quintic.Out)
        .start()
        .on('complete', () => {
          dispatch('LOAD_GAME_MAP')
        })
    })
}

// TRIGGER Map
function onMapClick(camera: any, mesh: any, dispatch: any) {
  new TWEEN.Tween(camera.position)
    .to({
      x: -249.93,
      y: 1.53,
      z: 5.49
    }, 2500)
    .interpolation(TWEEN.Interpolation.CatmullRom)
    .easing(TWEEN.Easing.Quintic.InOut)
    .start()
    .on('complete', () => {
      
    })
    new TWEEN.Tween(mesh.position)
      .to({
        x: -60,
        y: 30,
        z: 23
      }, 1500)
      .delay(1000)
      .interpolation(TWEEN.Interpolation.CatmullRom)
      .easing(TWEEN.Easing.Quintic.Out)
      .start()
      .on('complete', () => {
        GAService.pageView('homePage')
        dispatch('LOAD_GAME_MAP')
      })
}
// TRIGGER QUIZZ
function onQuizzClick(camera: any, mesh: any, dispatch: any) {
  new TWEEN.Tween(camera.position)
    .to({
      x: -110.29,
      y: 1.53,
      z: -224.35
    }, 1500)
    .interpolation(TWEEN.Interpolation.CatmullRom)
    .easing(TWEEN.Easing.Quintic.InOut)
    .start()
    .on('complete', () => {
      new TWEEN.Tween(mesh.position)
      .to({
        x: -70,
        y: 30,
        z: -110
      }, 1500)
      .interpolation(TWEEN.Interpolation.CatmullRom)
      .easing(TWEEN.Easing.Quintic.Out)
      .start()
      .on('complete', () => {
        GAService.pageView('homePage')
        dispatch('LOAD_GAME_QUIZZ')
      })
    })
}

// TRIGGER QUIZZ
function onSunriseClick(camera: any, mesh: any, dispatch: any) {
  new TWEEN.Tween(camera.position)
    .to({
      x: 0,
      y: -40,
      z: 180
    }, 2500)
    .interpolation(TWEEN.Interpolation.CatmullRom)
    .easing(TWEEN.Easing.Quintic.InOut)
    .start()
    .on('complete', () => {
      
    })
  new TWEEN.Tween(mesh.position)
    .to({
      x: -3,
      y: 30,
      z: -10
    }, 2000)
    .delay(250)
    .interpolation(TWEEN.Interpolation.CatmullRom)
    .easing(TWEEN.Easing.Quintic.Out)
    .start()
    .on('complete', () => {
      GAService.pageView('homePage')
      dispatch('LOAD_SUNRISE')
    })
}

// Default Scene position
async function resetScenePosition(scene: any, camera: any, dispatch: any, game: string) {
  const resetMap: any = {
    map: { x: -350, y: 2.14, z: -43.23 },
    quizz: { x: -154.40, y: 2.14, z: -314.09 },
    sunrise: { x: 0, y: 0, z: 350 }
  }
  new TWEEN.Tween(scene.position)
    .to({ x: 0, y: 0, z: 0 }, 1000)
    .interpolation(TWEEN.Interpolation.CatmullRom)
    .easing(TWEEN.Easing.Quintic.Out)
    .start()
    .on('complete', () => {
    })
  new TWEEN.Tween(camera.position)
    .to(resetMap[game], 1500)
    .interpolation(TWEEN.Interpolation.CatmullRom)
    .easing(TWEEN.Easing.Quintic.Out)
    .start()
    .on('complete', () => {
      dispatch('DEFAULT_RESET_SCENE')
    })
}

function ShowRoom(props: any) {
  const { dispatch } = props
  const mesh: any = useRef()
  const { scene, camera } = useThree()
  // eslint-disable-next-line
  useEffect(() => {
    GAService.pageView('homePage')
    dispatch('FIRST_INIT')
  // eslint-disable-next-line    
  }, [])
  useEffect(() => {
    if (props.isZooming) {
      new TWEEN.Tween(scene.position)
        .to({
          y: 25,
        }, 2500)
        .interpolation(TWEEN.Interpolation.CatmullRom)
        .easing(TWEEN.Easing.Quintic.InOut)
        .start()
    } else {
      new TWEEN.Tween(scene.position)
        .to({
          y: 0,
        }, 1500)
        .interpolation(TWEEN.Interpolation.CatmullRom)
        .easing(TWEEN.Easing.Quintic.InOut)
        .start()
    }
  }, [props.isZooming])
  useEffect(() => {
    if (props.resetScene) {
      resetScenePosition(scene, camera, dispatch, props.game)
    }
  // eslint-disable-next-line
  }, [props.resetScene])
  const glb = useLoader(GLTFLoader, `${process.env.REACT_APP_OSS}${process.env.REACT_APP_RENDERING}`)
  // useFrame(() => (mesh.current.rotation.y += 0.002))
  return <primitive ref={mesh} object={glb.scene} position={[0, -25, 0]} />
}

function TriggerGameButton(props: any) {
  const { position, rotation, game, trigger, isShowroom, dispatch, move, visibility } = props
  const mapMesh: any = useRef()
  const { camera, scene } = useThree()

  useEffect(() => {
    if (!move && game === trigger) {
      onLaunchGame()
    }
  // eslint-disable-next-line
  }, [move, game, trigger])

  useEffect(() => {
    if (mapMesh && mapMesh.current) {
      mapMesh.current.scale.x = 0.4
      mapMesh.current.scale.y = 0.4
      mapMesh.current.scale.z = 0.4
      new TWEEN.Tween(mapMesh.current.scale)
        .to({
          x: 1.2,
          y: 1.2,
          z: 1.2
        }, 1000)
        .interpolation(TWEEN.Interpolation.CatmullRom)
        .repeat(Infinity)
        .yoyo(true)
        .start()
    }
  }, [])

  const onLaunchGame = (e?: any) => {
    e && e.stopPropagation()
    if (isShowroom) {
      if (trigger === 'quizz') {
        dispatch('ZOOM_TO')
        onQuizzClick(camera, scene, dispatch)
      } else if (trigger === 'map') {
        dispatch('ZOOM_TO')
        onMapClick(camera, scene, dispatch)
      } else if (trigger === 'sunrise') {
        dispatch('ZOOM_TO')
        onSunriseClick(camera, scene, dispatch)
      }
    }
  }

  return (
  <>
    <mesh visible={visibility} ref={mapMesh} position={position} rotation={rotation} onPointerDown={onLaunchGame}> 
      <circleBufferGeometry attach="geometry" args={[3, 30]}/>
      <meshBasicMaterial attach="material" transparent opacity={1} side={DoubleSide} color={'#be0f34'} />
    </mesh>
    <mesh visible={visibility} ref={mapMesh} position={position} rotation={rotation} onPointerDown={onLaunchGame}>
      <circleBufferGeometry attach="geometry" args={[8, 50]}/>
      <meshBasicMaterial attach="material" transparent opacity={0.4} side={DoubleSide} color={'#be0f34'} />
    </mesh>
  </>)
}

const CameraControls = (props: any) => {
  const { enableZoom, enableRotate, dispatch, isZooming } = props
  const [distance, setDistance] = useState(350)
  const { camera } = useThree()
  const controls: any = useRef()

  useEffect(() => {
    if (distance < 330) {
      if (!isZooming) {
        dispatch('USER_ZOOM_IN')
      }
    } else {
      if (isZooming) {
        dispatch('USER_ZOOM_OUT')
      }
    }
  }, [distance])

  useFrame((state) => {
    controls && controls.current && setDistance(Math.round(camera.position.distanceTo(controls.current.target)))
    TWEEN.update()
    controls.current.update()
  })

  return <OrbitControls ref={controls} autoRotate={false} enableDamping={true} enableZoom={enableZoom} enableRotate={enableRotate} enablePan={false} minPolarAngle={Math.PI/2} minDistance={120} maxDistance={350} maxPolarAngle={Math.PI/2} />
}


const Rendering: FC = () => {
  const [state, dispatch] = useReducer(
    stateReducer,
    initialState
  );

  const color = new Color(0x87ceeb)

  return (
    <div className="rendering">
      <div className={!state.isShowroom || state.isZooming ? 'rendering__header rendering__header__out' : 'rendering__header'}>
        <img className="rendering__header__cdf" alt="cdf sunrise" src={`${process.env.REACT_APP_OSS}cdf.svg`} />
        <div className="rendering__header__text1" >与娇韵诗<br/>携手出游</div>
        <div className="rendering__header__text2" >植物配方，<br/>随时随地娇宠肌肤。</div>
        <img className="rendering__header__plane" alt="plane_journey" src={`${process.env.REACT_APP_OSS}bond.svg`} />
      </div>
      <div className="rendering__body">
        {state.showTabbar  && <RuleCard {...state} dispatch={dispatch} />}
        {state.launch && state.game === 'map' && <LaunchGame {...state} dispatch={dispatch}/>}
        {state.launch && state.game === 'quizz' && <Quiz {...state} dispatch={dispatch}/>}
      </div>
      {/* @ts-ignore */}
      <Canvas className='rendering__canvas' gl2={true} concurrent={true} invalidateFrameloop={state.showTabbar || !state.isLoaded} camera={{ position: [0, 0, 350], fov: 45}}>
        <ambientLight position={[0, 100, 20]} />
        <hemisphereLight position={[0, 200, 0]} intensity={1} skyColor={color}/>
        <CameraControls {...state} dispatch={dispatch} />
        <Suspense fallback={
          <Html>
            <div className="loading">
              <img alt="loading" src={`${process.env.REACT_APP_OSS}loading-loop.gif`} />
            </div>
          </Html>
        }>
          {(process.env.REACT_APP_MODE !== 'PROD') && <Stats />}
          <ShowRoom {...state} dispatch={dispatch} />
          <TriggerGameButton visibility={(state.isShowroom || (!state.showTabbar && state.game === 'map'))} {...state} dispatch={dispatch} trigger={'map'} position={[-55, -10, -23]} rotation={[0, -5, 0]} />
          <TriggerGameButton visibility={(state.isShowroom || (!state.showTabbar && state.game === 'quizz'))} {...state} dispatch={dispatch} trigger={'quizz'} position={[0, -30, -25]} rotation={[0, 45, 0]}/>
          <TriggerGameButton visibility={(state.isShowroom || (!state.showTabbar && state.game === 'sunrise'))} {...state} dispatch={dispatch} trigger={'sunrise'} position={[0, -30, 65]} rotation={[0, 0, 0]}/>
        </Suspense>
      </Canvas>
      <div className="rendering__footer">
        {!state.showTabbar ? (
          <div>
            <div className="rendering__footer__icon">
              <img alt="icon3d" src={`${process.env.REACT_APP_OSS}3d_icon.svg`} />
            </div>
        <div className="rendering__footer__text">360度旋转，点击红点，发现更多</div>
          </div>
        ) : (
          <Tabbar {...state} dispatch={dispatch} />
        )}
      </div>
    </div>
  )
}

export default Rendering;