export const icon_angry = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/angry.svg'
export const icon_gloomy = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/gloomy.svg'
export const icon_happy = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/happy.svg'
export const icon_sade = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/sade.svg'
export const icon_veryHappy = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/very_happy.svg'

export const icon_angry_active = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/angry-active.svg'
export const icon_gloomy_active = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/gloomy-active.svg'
export const icon_happy_active = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/happy-active.svg'
export const icon_sade_active = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/sade-active.svg'
export const icon_veryHappy_active = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/very_happy-active.svg'

export const product0 = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/product0.png'
export const product1 = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/product1.png'
export const product2 = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/product2.png'
export const product3 = 'https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/product3.png'







